<template>
  <div>
    <h5 class="title">村口壹站</h5>
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item,index) in listData" :key="index">
        <div class="cardItem" @click="toDetail(item)">
          <img :src="item.url">
          <div v-if="item.subCode && item.subCode == 'express'" class="express-num">{{item.num}}</div>
          <p class="cardName">{{item.value}}</p>
          <p class="cardDes">{{item.desc}}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  name: "swiperItem",
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props: ['listData'],

  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        // spaceBetween: 10,
      }
    }
  },
  mounted () {
  },
  methods: {
    toDetail (item) {
      if (item.name === '村口快递') {
        this.$router.push({
          path: '/eSDetail',
        })
      } else {
        let path = item.reqUrl.split('?')[0]
        let id = item.reqUrl.split('id=')[1]
        this.$router.push({
          path: path,
          query: {
            id: id
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.swiper-slide {
  width: calc(~"33% + 20px"); //每一块宽度
  margin-right: 10px;
  &:nth-last-child(1){
    margin-right: 15px;
  }
}

.title {
  margin-bottom: 15px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}

.cardItem {
  position: relative;
  img {
    width: 100%;
  }
  .express-num {
    position: absolute;
    left: 0.24rem;
    top: 0.32rem;
    z-index: 100;
    font-size: 0.24rem;
    color: #3F7C53;
    background: #fff;
    width: 0.85rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 0.2rem;
    text-align: center;
  }
  .cardName {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    left: 15px;
    bottom: 35px;
  }

  .cardDes {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    left: 15px;
    bottom: 15px;
  }
}
</style>