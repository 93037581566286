<template>
  <div class="homeWarp">
    <div class="weatherDiv">
      <div class="flex weather-top" >
        <div class="flex align_items_center weather_temp" v-if="localCity != '' && localCity != undefined && weatherData != undefined">
          <div class="blod">{{weatherData.condition.temp}}</div>
          <div>℃</div>
          <div class="flex align_items_center weather_info_text">{{localCity}}</div>
          <div class="flex align_items_center weather_info_text">{{weatherData.condition.condition}}</div>
        </div>
        <div v-else></div>
        <a href="tel:400-032-5263"><img :src="phone"/></a>
      </div>
    </div>

    <!-- 便捷入口-->
    <div class="quick_door" >
      <div class="quick_door_item text_center flex align_items_center" @click="quickDoorClick(item)"
           v-for="(item,index) in topList" :key="index" style="" v-if="item.subCode != 'Page_Home_Map'">
        <van-image  class="quick_door_item_icon" :src="item.url"></van-image>
        <p class="quick_door_title width_full text_center" >{{item.name}}</p>
      </div>
    </div>
        <!-- -->
        <div>
          <div class="content logOutBg " v-if="!isLogin"  @click="siteClick()">
            <div class="item_title">我的站点</div>
          </div>
          <div v-else>
            <!--   市场人员登录-->
            <div v-if="isShow_Home_ToBeAudited"  class="check-pending" @click="checkPendingClick">
              <div class="check-pending-title">您有待审核的站点，请及时审批</div>
              <van-icon name="arrow" style="margin-right: 20px"  color="#3F7C53" size="16px"></van-icon>
            </div>
            <div class="content_sc flex" v-if="isShow_Home_MyTask">
              <div class="taskyy" @click="newSite">新建站点</div>
              <div style="margin-left:0.16rem; width: 100%">
                <div @click="siteClick()" v-if="isShow_Home_SiteManage" class="zdglyy">
                  <div class="yymanage_title bold">站点管理</div>
                  <div class="item_info">站点 <span class="yymanage_count">{{homeData.stationCount}}</span> 个</div>
                </div>
                <div style="background-color: #eeeeee;width: 1px;height: 0.16rem;">
                </div>
                <div @click="taskClick()" v-if="isShow_Home_MyTask" class="wdrwyy">
                  <div class="yytask_title bold">我的任务</div>
                  <div class="item_info">待完成 <span class="yytask_count">{{homeData.unfinishedTaskCount}}</span> 个</div>
                </div>
              </div>

            </div>

            <!--        站长登录-->
            <div class="content zzBg" v-if="(isShow_Home_MySite && isShowSiteInfo) || showSign" >
              <div @click="siteClick()">
                <div class="bold item_title">我的站点</div>
                <div class="flex item_info" >
                  <div v-if="!isShowSiteInfo && !showSign" class="flex">
                    <div>余额</div>
                    <div class="item_info_count">{{homeData.currentBalance}}</div>
                    <div>万元</div>
                  </div>
                  <div v-if="isShowSiteInfo && homeData.stationStep != 'JOINREVIEW_FAIL'
                  && homeData.stationStep != 'JOINTRIAL_FAIL' && homeData.stationStep != 'CUSSERVREVIEW_FAIL'" >{{homeData.stepStr}}</div>
                  <div v-else-if="isShowSiteInfo || showSign" style="color:#F4664A;" >{{homeData.stepStr}}</div>
                </div>
              </div>
            </div>
            <div class="zzSigned" v-if="isShow_Home_MySite && !isShowSiteInfo">
              <div class="top">
                <div class="flex" style="align-items:center">
                  <img :src="moneyicon" class="moneyicon-img">
                  <span>累计收入（元）</span>
                </div>
                <div class="money">{{sumData.accumulationIncome}}</div>
                <img :src="siteicon" class="siteicon-img">
              </div>
              <div class="mid flex">
                <div class="flex mid-item" @click="addIncomeClick()">
                  <div>
                    <p>最新收入（元）</p>
                    <span>{{sumData.addIncome}}</span>
                  </div>
                  <img :src="righticon" class="righticon-img">
                </div>
                <div class="mid-line"></div>
                <div class="flex mid-item" @click="siteClick()">
                  <div>
                    <p>站点业绩（万元）</p>
                    <span>{{sumData.currentBalance}}</span>
                  </div>
                  <img :src="righticon" class="righticon-img">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- banner-->
        <div class="bannerDiv ">
          <van-swipe :autoplay="3000" class="bannerSwipe" indicator-color="#3F7C53" style="z-index: 0">
            <van-swipe-item  v-for="(item, index) in bannerList " :key="index" >
              <img :src="item.url"  @click="quickDoorClick(item)">
            </van-swipe-item>
          </van-swipe>
        </div>

        <!--        优质站点-->
        <div class="quality_content">
          <swiper-item :list-data="qualityStationArr"> </swiper-item>
        </div>

        <!--安全中心-->
        <div v-if="isShow_Security_Center" @click="toSafety">
          <img :src="safetyUrl" class="safetyImg">
        </div>

        <!--    全屏弹窗-->
        <van-overlay :show="show" @click="show = false">
          <div class="dialog-show" @click.stop>
            <van-icon name="close" color="#fff" size="0.8rem" @click="show = false"/>
            <img :src="dialogSrc" class="block" @click = "show = false">
          </div>
        </van-overlay>
        <router-view />
        <!--        乡村资讯-->
        <div class="news">
          <div class="flex align_items_center width_full news_header">
            <span class="bold news_header_title">乡村热点资讯</span>
          </div>
          <div v-for="(item,index) in newsInfoList" :key="index">
            <ruralInformationPicOne v-if="item.infoTemplate == 1" :info="item" @click.native="clickNews(item.id)"> </ruralInformationPicOne>
            <ruralInformationPicThree v-if="item.infoTemplate == 3" :info="item" @click.native="clickNews(item.id)"> </ruralInformationPicThree>
          </div>
        </div>

        <van-tabbar v-model="active" route active-color="#3F7C53" inactive-color="#B5BBC9">
          <van-tabbar-item replace to="/main/homePage" >首页
            <template #icon="props">
              <img :src="props.active ? homeIcon.active : homeIcon.inactive" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/News/newsHome">村口头条
            <template #icon="props">
              <img :src="props.active ? newsIcon.active : newsIcon.inactive" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/main/mine" >我的
            <template #icon="props">
              <img :src="props.active ? mineIcon.active : mineIcon.inactive" />
            </template>
          </van-tabbar-item>
        </van-tabbar>
        <el-amap ref="map" style="display: none" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin"  :events="events" class="amap-demo">
        </el-amap>
    </div>
</template>

<script>
import { AMapManager,lazyAMapApiLoaderInstance} from 'vue-amap';
let amapManager = new AMapManager()
import { mapGetters } from 'vuex'
import { Swipe, SwipeItem, Icon, Toast, Overlay, Button  } from 'vant'
import { getHomeInfo,getInformationListForH5,getHomeQuickDoorUrl,getCondition, getSumData} from '@/utils/api'
import common from '@/utils/common'
import ruralInformationPicOne from "../News/ruralInformationSubviews/ruralInformationPicOne";
import ruralInformationPicThree from "../News/ruralInformationSubviews/ruralInformationPicThree";
import {Home_Page} from "../../utils/constantNum";
import swiperItem from '../../components/swiperItem'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Overlay.name]: Overlay,
    [Button.name]: Button,
    ruralInformationPicOne,
    ruralInformationPicThree,
    swiperItem
  },
  computed:{
    ...mapGetters([
      'isShow_Home_SiteManage',
      'isShow_Home_MyTask',
      'isShow_Home_MySite',
      'isShow_Home_Survey',
      'isShow_Home_ToBeAudited',
      'isShow_Security_Center'
    ])
  },
  data() {
    const self = this
    return {
      showSign:false,
      safetyUrl:require('@/assets/img/safety.png'),
      homeIcon:{
        active: require('@/assets/img/tabBar/home_1.png'),
        inactive: require('@/assets/img/tabBar/home_0.png'),
      },
      newsIcon:{
        active: require('@/assets/img/tabBar/news_1.png'),
        inactive: require('@/assets/img/tabBar/news_0.png'),
      },
      mineIcon: {
        active: require('@/assets/img/tabBar/mine_1.png'),
        inactive: require('@/assets/img/tabBar/mine_0.png'),
      },
      bannerList: [],
      show: false,
      dialogSrc: null,
      isShowSiteInfo:false,
      isShowSCInfo:false,
      isShowZZ:false,
      topList:[],
      homePage:{},
      homeData:{},
      searchValue:'',
      newsInfoList:[],
      isLogin:false,
      localCity:'北京市',
      areaName: '东城区',
      cityDesc:'',
      weatherData:undefined,
      active:1,
      // weatherImg: require(this.weatherData.backUrl),
      VRwidth: document.body.clientWidth + 200,
      VRheight: document.body.clientWidth / 2,
      vrImg: require('@/assets/img/vr.png'),
      qualityStationArr:[],
      qualityItemWidth:'',
      qualityItemHeight:'',
      amapManager,
      zoom: 12,
      center: [116.403419,39.924074],
      events: {
        init: (o) => {
          console.log('顶顶顶顶')
          console.log(o)
          console.log(o.getCenter())
          console.log('这里：',this.$refs.map.$$getInstance())
          o.getCity(result => {
              console.log('initMap:::',result)
          })
        },
      },
      plugin: [{
        pName: 'Geolocation',
        events: {
          init(o) {
            console.log("hhhhh")
            // o 是高德地图定位插件实例
            o.getCurrentPosition((status, result) => {
              console.log('定位结果：',result)
              self.localCity =  result.addressComponent.city?result.addressComponent.city:result.addressComponent.province
              self.areaName = result.addressComponent.city?result.addressComponent.city:result.addressComponent.district
              console.log("当前城市：",self.localCity )
             // self.toGetCondition()
              self.$nextTick();
            });
          }
        }
      }],
      phone: require('@/assets/img/home/phone.png'),
      moneyicon: require('@/assets/img/home/moneyicon.png'),
      righticon: require('@/assets/img/home/righticon.png'),
      siteicon: require('@/assets/img/home/siteicon.png'),
      sumData: {},

    }
  },
  methods:{
    //跳转到安全中心页面
    toSafety(){
      this.$router.push('/safety')
    },
    checkPendingClick () {
      this.$router.push({ name: 'checkpending' })
    },
    newSite() {
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'NewSite')
      this.$router.push('/newsiteName')
    },
    togetSumData() {
      getSumData({
        token: common.getItem('wxToken')
      }).then(res=>{
        this.sumData = res.data.data
      })
    },
    addIncomeClick() {
      this.$router.push({name: 'allincome'})
    },
    quickDoorClick(item){
      if(!common.getItem('wtToken') && parseInt(item.type) === 4){
        this.$store.dispatch('keepAliveState/setKeepAliveAction', 'login')
        this.$router.push('/main/login')
      }else {
        if (item.reqUrl == '' || item.reqUrl == undefined){
            // Toast('系统异常 请重试')
            return;
        }
        switch (parseInt(item.type)) {
          case 0:{
            if (item.reqUrl == '/drving'){
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'driving')
            }
            if (item.subCode == 'weather'){
                this.$router.push({path: '/main/weatherList', query:{'city': this.localCity}})
                return
            }
            if (item.subCode == 'SubCode_JGQ_Farm'){
                this.$router.push({path: '/farmProduct/farmProduct', query:{'city': this.localCity, 'area': this.areaName}})
                return
            }
            // if (item.subCode == 'Page_Home_News'){
            //     this.$router.push({path: '/News/newsList', query:{'city': this.localCity}})
            //     return
            // }


            this.$router.push({path: item.reqUrl})
          }
          break
          case 1:{//外部连接
            if (item.subCode === 'VR'){
              this.$router.push({path:'/VR',
                query:{
                  VRURL: item.reqUrl
                }})
            }//VR做单独处理
            else {
              window.location.href = item.reqUrl
            }
          }
          break
          case 2:{ // 充值缴费
            this.toRechargePayment()
          }
          break
          case 4:{ // 登录校验
            this.$router.push({path: item.reqUrl})
          }
          break
        }
      }
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    toRechargePayment(){
      let params =  this.isWeiXin() ? { wxFlag: true} : {}
        getHomeQuickDoorUrl(params).then(res => {
            // 加载状态结束
            if (res.code == 200) {
                if (res.data.businessCode && res.data.businessCode === 200) {
                    if (res.data.ofpayUrl != undefined) {
                        window.location.href = res.data.ofpayUrl
                        // this.$router.push({name:'homeQuickDoor',query:{reqUrl:res.data.ofpayUrl}})
                    }
                }
            }
        }).catch(() => {
        })
    },
    moreNews(){
        this.$router.push({name:'newsHome'})
    },
    clickNews(newsId){
        this.$router.push({name:'newsDeatail',query:{'newsId':newsId}})
    },
    onSearch(){

    },
    taskClick() {
        if(!common.getItem('wtToken')){
            this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
            this.$router.push('/main/login')
        }else {
            this.$store.dispatch('keepAliveState/addKeepAliveAction', 'myTask')
            this.$router.push({ name: 'myTask'})
        }
    },
    siteClick() {
      if(!common.getItem('wtToken')){
        this.$store.dispatch('keepAliveState/addKeepAliveAction', 'login')
        this.$router.push('/main/login')
      }else{
        let role = parseInt(common.getItem('userRole'))
        if (role === 1){ // 站长
          // this.$router.push({name:'notSign'})
          if(common.getItem('authStep') == 'needReal'){
            this.$store.dispatch('keepAliveState/addKeepAliveAction', 'idcardProving')
            this.$router.push({name: 'idcardProving'})
          }
          if(common.getItem('authStep') == 'needBankVerify'){
            this.$store.dispatch('keepAliveState/addKeepAliveAction', 'cardInfo')
            this.$router.push({name: 'cardInfo'})
          }
          if(common.getItem('authStep') == 'needBizLicense'){
            this.$router.push({name: 'businessLicense'})
          }
          if(common.getItem('authStep') == 'needSign'){
            this.$router.push({name:'waitSign'})
          }
          if(common.getItem('authStep') == 'signed'){
            switch (this.homeData.stationStep) {
              case 'CUSSERVREVIEW_': // 客服审核中
                this.$router.push({name:'waitSign',query:{'isHideBottom':'1'}})
                break;
              case 'CUSSERVREVIEW_FAIL': // 客服审核未通过
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'idcardProving')
                this.$router.push({name:'idcardProving'})
                break;
              case 'END': // 审核通过
                if(this.homeData.stepStr && this.homeData.stepStr==='您有协议待签署'){
                   this.$router.push({name:'notSign'})
                }else{
                  this.$router.push({name:'mysiteZZ'})
                }
                break;
            }
          }
        }else {
            this.$router.push({ name: 'mysite', query:{role: common.getItem('userRole'),pageType: Home_Page}})
        }
      }
    },
    toGetInformationListForH5(){
      getInformationListForH5({
        'pageIndex':'1',
        'pageSize':'3'
      }).then(res=>{
        // 加载状态结束
        if (res.code == 200){
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.newsInfoList = res.data.data.list;
          }
        }
      })
    },
    toGetHomeInfo(){
      getHomeInfo().then(res=>{
        if(res.code === 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            common.setItem('authStep',res.data.authStep);
            this.homeData = res.data;
            this.homePage = res.data.homePage;
            this.bannerList = this.homePage.homeBannerList;
            this.isShowSiteInfo = !(this.homeData.stationStep === 'END');
            this.showSign = this.homeData.stepStr === '您有协议待签署' ? true : false;
            this.isShowSCInfo = !(this.homeData.stationCount === undefined);
            this.topList = this.homePage.topList;
            this.qualityStationArr = res.data.homePage.exhibitions
            if(!this.isShowSiteInfo){
              this.togetSumData()
            }
          }
        }
      })
    },
    toGetCondition(){
        getCondition({
            'city':this.localCity
        }).then(res=>{
            if(res.code === 200) {
                if (res.data.businessCode && res.data.businessCode === 200) {
                    this.weatherData = res.data.data
                }
            }
        });
    },
    toGetConditionTimeStr(){
        var d = new Date()
        var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate()
        var months = new Array("一月", "二月", "三月", "四月", "五月", "六月", "七月","八月", "九月", "十月", "十一月", "十二月");
        var month = months[d.getMonth()]
        var weeks = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
        var weekDay = d.getDay();
        var week = weeks[weekDay];
        return day +'  ' + month +'  ' + week
    },
  },
  mounted() {
    this.isShowZZ = parseInt(common.getItem('userRole')) === 1
    if (common.getItem('wtToken') == undefined){
      this.isLogin = false
    } else  {
      this.isLogin = true
    }
    this.togetSumData()
    this.toGetHomeInfo()
    this.toGetInformationListForH5()
    this.qualityItemWidth = document.body.clientWidth / 3
    this.qualityItemHeight = 4*this.qualityItemWidth /5
    this.cityDesc = this.toGetConditionTimeStr()
   // this.toGetCondition()
    uwStatisticAction('/homePage','首页')
  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
  .safetyImg{
    width: 2.2rem;
    position: absolute;
    right: 0;
    bottom: 4rem;
    z-index: 9999;
  }
.homeWarp{
    font-family:PingFangSC-Regular,PingFang SC;
    background: white;
    font-size: 0.2rem;
    padding-top: 42px;

    .van-tabbar--fixed{
      position: absolute;
      height: 50px;
      z-index: 100;
    }
    .vr-img{
      width: 100%;
    }
    .top_header{
      position: relative;height: 4.35rem
    }
    .bannerDiv{
      background-color: white;
      z-index: -10 !important;
    }
    .vr-class{
      background: #F7F8F9;
      padding: 0.25rem 0.3rem 0.24rem;
      position: relative;
      width: 100%;
      overflow: hidden;
      .vr-frame{
        height: 2.64rem;
        overflow: hidden;
        border-radius: 0.16rem;
      }
      .vr-up{
        position: absolute;
        width: 92%;
        height: 2.64rem;
        left:0.3rem;
        top:0.25rem;
        z-index: 20;
        .site{
          width: 1.14rem;
          height: 0.5rem;
          color:#fff;
          background: #272829;
          border-radius: 0.08rem;
          font-size:0.18rem;
          text-align: center;
          line-height: 0.5rem;
        }
        .vr{
          color:#fff;
          width: 1.98rem;
          height: 0.64rem;
          line-height: 0.64rem;
          text-align: center;
          margin:8% auto;
          border-radius: 0.32rem;
          background: rgba(0, 0, 0, 0.4);
          font-size: 0.28rem;
        }
      }
    }
    .dialog-show{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .van-icon{
        bottom: 1rem;
        position: absolute;
      }
      .block{
        width: 6rem;
      }
    }
    .moneyicon-img{
      width: 0.32rem;
      height: 0.32rem;
    }
    .siteicon-img{
      width: 1.64rem;
      position: absolute;
      right: 0;
      top: 0.6rem;
    }
    .righticon-img{
      width:0.4rem;
      height: 0.4rem;
    }
    .zzSigned{
      margin: 0 0.3rem;
      height: 3.14rem;
      background: linear-gradient(28deg, rgba(33, 35, 37, 0.11) 0%, rgba(30, 147, 249, 0.49) 32%, rgba(20, 111, 216, 0.89) 100%, rgba(42, 123, 218, 0.89) 100%) linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
      box-shadow: 0px 8px 28px 0px rgba(144,171,212,0.4);
      border-radius: 20px;
      position: relative;
      .top{
        height: 1.62rem;
        padding: 0.36rem 0.3rem 0.3rem;
        span{
          font-size:0.3rem;
          color: #272829;
          margin-left:0.16rem;
        }
        .money{
          font-size: 0.4rem;
          color: #4A90E2;
          margin-left:0.48rem;
        }
      }
      .mid{
        background: #ECF5FC;
        height: 1.52rem;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 10;
        .mid-item{
          flex: 1;
          padding: 0.36rem 0.3rem;
          align-items: center;
          justify-content: space-between;
        }
        .mid-line{
          width: 0.02rem;
          background: #DEDEDE;
          height:0.68rem;
          margin-top: 0.46rem;
        }
        p{
          font-size:0.24rem;
          color: #9B9B9B;
          margin-bottom: 0.05rem;
        }
        span{
          font-size: 0.4rem;
          color: #4A4A4A;
        }
      }
    }
    .check-pending {
      background: #f5f5f5;
      color: #3f7c53;
      text-align: center;
      height: 31px;
      background-color: #f5f5f5;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.3rem 0.4rem;
      .check-pending-title {
        margin-left: 20px;
        font-size: 11px;
        height: 31px;
        line-height: 32px;
      }
    }
    .content{
        margin: 0 15px;
        padding: 19px;
        z-index: 10;
        position: relative;
        border-radius: 8px;
        /*box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.05);*/
        .content_logout{
            height: 69px;
            z-index: 20;
            background-color: white;
            .content_logout_left_image{
                width: 140px;
                // height: 80px;
                margin-right: 1px
            }
            .content_logout_left_info{
                margin-left: 0.4rem;
                font-size: 15px
            }
            .content_logout_right_image{
                margin-right: 0.2rem
            }
        }
        .item_title{
            font-size: 19px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #272829;
        }
        .item_info{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #616161;
            margin-top: 6px;
            .item_info_count{
                color: #3F7C53;
                margin: 0 4px;
            }
        }

    }
    .taskyy{
      background: url("../../assets/img/home/newtask.png");
      background-size: 100% 100%;
      // width: 2.58rem;
      width: calc(55% - 10px);
      height: 2.44rem;
      padding: 0.24rem 0.3rem;
      margin: 0;
      color: #3F7C53;
      font-size: 0.32rem;
      font-weight: bold;
    }
    .zdglyy{
      background: url("../../assets/img/home/yysitemanage.png");
      background-size: 100% 100%;
      // width: 4.16rem;
       width: 100%;
      height: 1.14rem;
      padding: 0.16rem 0 0.2rem 0.3rem;
      margin: 0;
    }
    .wdrwyy{
      background: url("../../assets/img/home/yysitetask.png");
      background-size: 100% 100%;
      // width: 4.16rem;
      width: 100%;
      height: 1.14rem;
      padding: 0.16rem 0 0.2rem 0.3rem;
      margin: 0;
    }
    .yymanage_title{
      font-size: 0.32rem;
      color: #426B9B;
    }
    .yytask_title{
      font-size: 0.32rem;
      color: #3C478C;
    }
    .yymanage_count{
      color: #426B9B;
    }
    .yytask_count{
      color: #3C478C;
    }
    .zdgl{
        background: url("../../assets/img/home/yyzdbg.png");
        background-size: 100% 100%;
        width: calc(50% - 10px);
        padding: 20px 0 20px 20px;
        margin: 0;
    }
    .wdrw{
        background: url("../../assets/img/home/yyrwbg.png");
        background-size: 100% 100%;
        width: calc(50% - 10px);
        padding: 20px 0 20px 20px;
        margin: 0;
    }
    .zzBg{
        background: url("../../assets/img/home/zzzdbg.png");
        background-size: 100% 100%;

    }
    .logOutBg{
        background: url("../../assets/img/home/zzzdbg.png");
        background-size: 100% 100%;
        height: 88px;
    }
    .Logincontent{

    }
    .content_sc{
        margin: 0 15px ;
        z-index: 10;
        position: relative;
        background-color: white;
        border-radius: 8px;
        .content_sc_item{
            width: 49%;
            border-radius: 8px;
            .img-wrap{
              align-items: flex-end;
            }
            .content_sc_item_image{
                width: 50.6px;
                height: 37.5px;
                margin-right: 1px;
            }
            .content_sc_item_rigth_item{
                height: 69px;
                border-radius: 8px;

                .siteCountDiv{
                    background-color: white;
                    display: flex;
                    font-size: 12px;
                    color: #999999;
                    height: 25px;
                    margin-top: -12px;
                    margin-left: 16px;
                    align-items: flex-end;
                }
                .siteCount{
                    font-size:12px;
                    color: #043A16;
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }
        }
    }
    .weather-top{
        width: 100%;
        justify-content: space-between;
        padding: 9px 15px;
        color: #272829;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        z-index: 1000;
      .weather_temp{

          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #272829;
      }
      .weather_info_text{
        font-size: 0.18rem;
        margin-left: 0.08rem;
      }
      img{
        width: 0.44rem;
        height: 0.44rem;
      }
    }
    .weather-wrap{
      padding: 0 20px 0 15px ;
      background: #F7F8F9;
    }
    .weather{
        height: 1.2rem;
        color: white;
        border-radius: 0.16rem;
        .weather_temp{
            margin-left: 25px;
            font-size: 34px;
        }
        .weather_info{
            margin-left: 11px;
            .weather_info_text{
                height: 19px;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #272829;
                margin-top: -2px;
            }
        }
        .weather_icon{
            flex-direction: column;
            justify-content: center;
            margin-right: 40px;
            .weather_icon_image{
                height: 22px;
                width: 22px;
                margin: 0px auto 5px auto
              }
            .weather_icon_text{
                font-size: 11px;
                font-weight: 200;
                margin:0px auto
            }
        }
    }
    .news{
        margin-top:20px;
        background-color: white;
        padding-bottom: 55px;
        .news_header{
            height:25px;
            .news_header_title{
                margin-left: 0.2rem;
                font-size: 18px
            }
        }
    }
    .quick_door{
        margin: 0 0 0.2rem 0;
        background-color: white;
        .quick_door_item{
            width: calc(25% );
            display: inline-block;
            vertical-align: middle;
            padding: 14px 0;

            .quick_door_item_icon{
                width: 44px;
                // height: 44px;
            }
        }
        .quick_door_title{
            font-size: 13px;
            margin: 0;
        }
    }
    .VDS-ShadowBg_home{
        border-radius: 8px;
        box-shadow:0px 2.5px 5px 5px rgba(0, 0, 0, 0.04) ;
    }
.content_bg{
/*margin-top: -30px;*/
background-color: white;
height: 50px;
z-index: 10;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
position: relative;
top: -0.5rem;
/*background: red;*/
}

}
.quality_content{
  margin:  0;
  padding: 20px 0 20px 15px;
  background: #f7f8f9;
}
</style>
<style lang="less">
.homeWarp .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 20px  15px 26px 15px ;
  img{
    width: 100%;
    height: 98px;
    border-radius: 8px;
  }


}
.homeWarp{
  .van-swipe__indicator{
   width: 0.24rem;
    height: 0.04rem;
    border-radius: 0;
  }
  .van-swipe__indicator--active{
    width: 0.24rem;
    height: 0.04rem;
    border-radius: 0;
  }
}
</style>
